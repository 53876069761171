

@import "./assets/styles/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: 'Montserrat';
  // color: $white;
  font-family: 'Raleway', sans-serif;
  font-size: 1.06rem;
  @include mediaM(750px){
      font-size: 0.95rem;
  }
}
a {
  color: inherit;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: inherit;
  }
}
h1,
h2,
h3,
h4,
h6{
  font-weight: 600;
}

#root {
  width: 100%;
  overflow-x: hidden;
}

.selected-nav {
  color: #FF8FCC;
  opacity: 1;
  font-weight: 900;
  font-family: "Avenir black";
  span {
    color: #FF8FCC;
    opacity: 1;
    font-family: "Avenir black";
    font-weight: 900;
  }
  button{
    color: $black;
  }
}